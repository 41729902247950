import React from "react"

import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

const langNames = {
  "en": "english",
  "fr": "français"
};

const LanguageSelector = () => (
  <IntlContextConsumer>
    {({ languages, language: currentLocale }) => (
      <LangMenu currentLocale={currentLocale} languages={languages} />
    )}
  </IntlContextConsumer>
)

const LangMenu = ({ languages, currentLocale }) => {

  return (
    <ul className="text-sm text-gray-700 list-none p-0 flex items-center">
      {languages.map(language => language !== currentLocale && (
        <li key={language}>
          <button type="button" className="inline-block py-2 px-3 text-gray-900 hover:text-gray-700 no-underline appearance-none" onClick={() => changeLocale(language)}>{langNames[language] ?? language}</button>
        </li>
      ))}
    </ul>
  );
}

export default LanguageSelector
