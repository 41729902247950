/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from 'gatsby-plugin-react-intl'

import Header from "./Header"

const DefaultLayout = ({ children }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          shortTitle
        }
      }
    }
  `)

  return (
    <>
      <Header
        title={intl.formatMessage({id: 'siteMetadata.title', defaultMessage: data.site.siteMetadata.title})}
        shortTitle={intl.formatMessage({id: 'siteMetadata.shortTitle', defaultMessage: data.site.siteMetadata.shortTitle})}
      />
      <main>
        {children}
      </main>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
