import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import DefaultLayout from "../components/DefaultLayout"
import Seo from "../components/Seo"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <DefaultLayout>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({id: 'siteMetadata.title'})}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <section className="container m-autopy-8 text-left sm:text-center m-auto mt-10">
        <h1>
          <FormattedMessage id="index.hello" defaultMessage="Welcome to simplefoncier.ca" />
        </h1>
        <h2 className="mt-2 mb-4">
          <FormattedMessage id="index.welcome" defaultMessage="In the following months, we'll let you see a site that helps you find deals and gems in the land register of Quebec province." />
        </h2>
      </section>
    </DefaultLayout>
  )
}

export default IndexPage
