import PropTypes from "prop-types"
import React from "react"

import Link from "./Link";
import LanguageSelector from "./LanguageSelector";

export default function Header(props) {
  const { title, shortTitle } = props;

  return (
    <header>
      <nav className="font-sans bg-white text-center flex justify-between overflow-hidden">
        <Link className="text-sm text-gray-700 p-0 mt-1" to="/">
          <h1 className="hidden md:block text-md m-0 hover:text-gray-500">{title}</h1>
          <h1 className="block md:hidden text-md m-0 hover:text-gray-500">{shortTitle}</h1>
        </Link>
        <ul className="text-sm text-gray-700 list-none p-0 mt-1 flex items-center">
          <li><Link to="/about_us" className="inline-block mx-3 text-gray-900 hover:text-gray-500 no-underline">About Us</Link></li>
          <li>
            <LanguageSelector className="px-3" />
          </li>
          {/*
          <li><Link to="/account/login" className="inline-block py-2 px-3 text-gray-900 hover:text-gray-700 no-underline">Log In</Link></li>
          <li>
            <button className="bg-black hover:bg-text-gray-800 text-white ml-4 py-2 px-3">
              Sign Up
            </button>
          </li>
          */}
        </ul>
      </nav>
    </header>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string.isRequired
};
